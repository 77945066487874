<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Reporte Resumen Saldo de Morosos</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
            <b-row class="justify-content-center">

              <b-col lg="6">
                  <b-form-group>
                    <label>Analista: </label>
                    <v-select placeholder=" Todos " class="w-100" :filterable="false" label="text" v-model="analyst" @search="SearchanAlysts" :options="analysts"></v-select>
                    <small v-if="errors.analyst" class="form-text text-danger" >Seleccione un analista</small>
                  </b-form-group>
                </b-col>


                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente: </label>
                    <v-select placeholder=" Todos " class="w-100" :filterable="false" label="text" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Fecha al:">
                    <b-form-input type="date" v-model="report.to" ></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>


                <b-col sm="2" >
                  <b-form-group label="Rango:">
                    <b-form-select v-model="report.range" :options="range"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Sede:">
                    <b-form-select v-model="report.branch_office" :options="branch_office"></b-form-select>
                  </b-form-group>
                </b-col>


                <b-col sm="12" md="1">
                  <b-form-group>
                    <label for="">.</label>
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group>
                    <label for="">.</label>
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

                

            </b-row>
          </b-form>


              <div class="table-responsive mt-3">
                <table class="table table-report-horizontal table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center" colspan="23">RESUMEN DE SALDO MOROSOS  AL {{report.to}} </th>
                    </tr>
                    <tr>
                      <th rowspan="2" class="text-center">#</th>
                      <th rowspan="2" class="text-center">Codigo</th>
                      <th rowspan="2" class="text-center">Nombres y Apellidos</th>
                      <th rowspan="2" class="text-center">Dirección</th>
                      <th rowspan="2" class="text-center">Teléfono</th>
                      <th rowspan="2" class="text-center">Ciclo</th>
                      <th rowspan="2" class="text-center">Sede</th>
                      <th rowspan="2" class="text-center">Tipo <br> Crédito</th>
                      <th rowspan="2" class="text-center">Tipo <br> Programa</th>
                      <th rowspan="2" class="text-center">Crédito</th>
                      <th rowspan="2" class="text-center">Fecha <br> Venc.</th>
                      <th rowspan="2" class="text-center">Días <br> Mora</th>
                      <th colspan="3" class="text-center">DESEMBOLSO</th>
                      <th colspan="4" class="text-center">PAGOS</th>
                      <th colspan="4" class="text-center">DEUDA</th>
                    </tr>
                    <tr>
                      
                      <th class="text-center">Capital</th>
                      <th class="text-center">Interés</th>
                      <th class="text-center">Total</th>

                      <th class="text-center">Capital</th>
                      <th class="text-center">Interés</th>
                      <th class="text-center">Mora</th>
                      <th class="text-center">Total</th>

                      <th class="text-center">Capital</th>
                      <th class="text-center">Interés</th>
                      <th class="text-center">Mora</th>
                      <th class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(item, it) in data_table" :key="it">
                      <td class="text-center">{{ it + 1 }}</td>
                      <td class="text-center">{{ item.code }}</td>
                      <td class="text-left">{{ item.client_name }}</td>
                      <td class="text-left">{{ item.client_address }}</td>
                      <td class="text-left">{{ item.client_cell_phone }}</td>
                      <td class="text-left">{{ item.dues }}</td>
                      <td class="text-left">{{ item.branch_office_name }}</td>
                      <td class="text-left">{{ item.products_name }}</td>
                      <td class="text-left">{{ item.products_type_name }}</td>
                      <td class="text-left">{{ item.credit }}</td>

                      <td class="text-right">{{ item.pending_payment_date }}</td>
                      <td class="text-right">{{ item.pending_days_delinquency }}</td>
   
                      <td class="text-right">{{ item.disburse_capital }}</td>
                      <td class="text-right">{{ item.disburse_interest }}</td>
                      <td class="text-right">{{ item.disburse_total }}</td>

                      <td class="text-right">{{ item.paid_capital }}</td>
                      <td class="text-right">{{ item.paid_interest }}</td>
                      <td class="text-right">{{ item.paid_delinquency }}</td>
                      <td class="text-right">{{ item.paid_total }}</td>


                      <td class="text-right">{{ item.balance_capital }}</td>
                      <td class="text-right">{{ item.balance_interest }}</td>
                      <td class="text-right">{{ item.balance_delinquency }}</td>
                      <td class="text-right">{{ item.balance_total }}</td>
                      
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="12" class="text-right">TOTALES</td>
                      <td class="text-right">{{ total.disburse_capital }}</td>
                      <td class="text-right">{{ total.disburse_interest }}</td>
                      <td class="text-right">{{ total.disburse_total }}</td>
    
                      <td class="text-right">{{ total.paid_capital }}</td>
                      <td class="text-right">{{ total.paid_interest }}</td>
                      <td class="text-right">{{ total.paid_delinquency }}</td>
                      <td class="text-right">{{ total.paid_total }}</td>

                      <td class="text-right">{{ total.balance_capital }}</td>
                      <td class="text-right">{{ total.balance_interest }}</td>
                      <td class="text-right">{{ total.balance_delinquency }}</td>
                      <td class="text-right">{{ total.balance_total }}</td>
               
                    </tr>
                  </tfoot> 
                
                </table>
              </div>
  
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<style>
        .table-report-horizontal {
            width: 100%;
            border-collapse: collapse;
            overflow-x: auto; 
        }
    </style>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportDelinquentBalanceSummary',
      role:1,
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
      total : {
        disburse_capital : '0.00',
        disburse_interest : '0.00',
        disburse_total : '0.00',

        paid_capital : '0.00',
        paid_interest : '0.00',
        paid_delinquency : '0.00',
        paid_total : '0.00',

        balance_capital : '0.00',
        balance_interest : '0.00',
        balance_total : '0.00',
      },

      report:{
        id_user:'all',
        to:moment(new Date()).local().format("YYYY-MM-DD"),
        range:'all',
        branch_office:'all',
      },
      users : [],
      user: null,
      analysts : [],
      analyst: null,
      client: null,
      clients: [],
      range:[
        { value:'all', text: 'Todos'},
        { value:1, text: 'Moroso'},
        { value:2, text: 'Con problema potencial'},
        { value:3, text: 'Con problema legal'},
        { value:4, text: 'Con problema judicial'},
        { value:5, text: 'Pérdida '},
      ],
      program_type:[],
      branch_office:[],
  
      errors:{
        id_user:false,
        to:false,
      }
    };
  },
  mounted() {
   this.ListUsers();
   this.ListBranchOffice();
  },
  methods: {
    ListUsers,
    ListProducts,
    ListProductsType,
    ListBranchOffice,
    SearchPartner,
    Validate,
    Report,
    ExportExcel,
    SearchClients,
    SearchanAlysts,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function SearchClients(search, loading) {
  
  let me = this;
   let url = this.url_base + "client/search";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}

function SearchanAlysts(search, loading) {
  
  let me = this;
   let url = this.url_base + "user/search-users-select";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.analysts = response.data.result;
           loading(false);
     })
   }
}

function ListUsers() {
  let me = this;
  let url = me.url_base + "active-users";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.users = response.data.result;
      }
    });
}


function ListProducts() {
  let me = this;
  let url = me.url_base + "data/get-product-actives";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_type = [{ value: 'all', text: '- Todos -'}];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.credit_type.push({ value: element.id_product, text:  element.name });
        }
      }
    });
}


function ListProductsType() {
  let me = this;
  let url = me.url_base + "data/get-product-type-actives";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.program_type = [{ value: 'all', text: '- Todos -'}];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.program_type.push({ value: element.id_product_type, text:  element.name });
        }
      }
    });
}

function ListBranchOffice() {
  let me = this;
  let url = me.url_base + "active-branch-office";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.branch_office = [{ value: 'all', text: '- Todos -'}];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.branch_office.push({ value: element.id_branch_office, text:  element.name });
        }
      }
    });
}


function SearchPartner(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.users = response.data;
            loading(false);
      })
    }
}


function ExportExcel() {  
  
  this.errors.to = this.report.to.length == 0 ? true : false;
  if (this.errors.to == true) { return false; }
  let id_client = this.client == null ? 'all':this.client.value;
  let id_user = this.analyst == null ? 'all': this.analyst.value;
  let me = this;
  let url = me.url_base + "excel/report-delinquent-balance-summary/"+id_user+"/"+id_client+"/"+me.report.to+"/"+me.report.range+"/"+me.report.branch_office;
  window.open(url,'_blank');
}

function Validate() {

  this.errors.to = this.report.to.length == 0 ? true : false;
  if (this.errors.to == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  
  this.Report();
}


function Report() {
  let me = this;
  me.report.id_client = me.client == null ? 'all': me.client.value;
  me.report.id_user = me.analyst == null ? 'all': me.analyst.value;
  let data = me.report;
  let url = this.url_base + "report/delinquent-balance-summary";
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result.credits;
        me.total = response.data.result.total;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
     .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}





</script>
